import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Layout from "../components/Layout.en"
import PageTitle from "../components/PageTitle"
import SilverLabel from "../images/logo-silver-small.svg"
import GradientImage from "../images/silver-gradient.png"
import SilverLabelEU from "../images/europe/download-logo-silver.png"

const ApplicationProcedureSilver = () => {
  const [steps, setSteps] = useState(() => [true, ...new Array(7).fill(false)])

  function toggleCard(index) {
    setSteps(previousState => {
      const newSteps = [...previousState].map((step, i) =>
        i === index ? !step : false
      )
      return newSteps
    })
  }

  return (
    <React.Fragment>
      <PageTitle title={`Cyber Trust ${process.env.GATSBY_VARIANT} Silver Label`} />
      <Layout>
        <div className="application-procedure-gradientimg--wrapper">
          <img src={GradientImage} alt="Gradient" title="Gradient" />
        </div>
        <div className="page__wrap">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a>{`Cyber Trust ${process.env.GATSBY_VARIANT} Silver Label`}</a>
              </li>
            </ul>
          </div>
          <div className="application-procedure">
            <h1>{`Cyber Trust ${process.env.GATSBY_VARIANT} Silver Label`}</h1>
            <div className="application-procedure__wrapper">
              <div className="image" style={{ maxWidth: 125 }}>
                <img
                  src={
                    process.env.GATSBY_VARIANT === "Austria"
                      ? SilverLabel
                      : SilverLabelEU
                  }
                  className="mw-100"
                  alt="Cyber Trust Austria Silver Label"
                  title="Cyber Trust Austria Silver Label"
                />
              </div>
              <h3>{`Process for ordering a Cyber Trust ${process.env.GATSBY_VARIANT} Silver Label`}</h3>
              <div className="page__flex">
                <div className="application-procedure__left">
                  <div className="application-procedure__left--block">
                    <div
                      className={`application-procedure__left--box ${
                        steps[0] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(0)}
                      >
                        Online <strong>request</strong>
                      </div>
                      <div className="body">
                        The{" "}
                        <a
                          className="js-popup"
                          onClick={() =>
                            navigate("/en/label-beantragen?label=SILVER")
                          }
                        >
                          request
                        </a>{" "}
                        for the label is done online on this website.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[1] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(1)}
                      >
                        <strong>Automated Web Scoring</strong> (of the submitted
                        qualified domain)
                      </div>
                      <div className="body">
                        An automated security scoring of the domain that you
                        have declared is conducted. This is only passive and not
                        harmful in any way.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[2] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(2)}
                      >
                        <strong>Answering</strong> the online questionnaire
                      </div>
                      <div className="body">
                        You receive a link to answer the 25 questions of the
                        KSV1870 A-rating. You enter your answers there.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[3] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(3)}
                      >
                        <strong>Validation</strong> of answers incl. possibility
                        for clarifications
                      </div>
                      <div className="body">
                        An expert validates the answers with regards to
                        completeness, consistency, and plausibility. In case of
                        unclarities there is the possibility to ask back. Based
                        on the validation a report and a preliminary risk rating
                        is produced.
                      </div>
                    </div>
                    {/* <div
                      className={`application-procedure__left--box ${
                        steps[4] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(4)}
                      >
                        <strong>​Conduction</strong> of the audit
                      </div>
                      <div className="body">
                        From the list of accredited{" "}
                        <a
                          className="js-popup"
                          onClick={() => navigate("/en/partner")}
                        >
                          audit partners
                        </a>{" "}
                        you select a partner and order the audit; we will
                        support you if necessary. The charging is done directly
                        between you and the audit partner. The audit is
                        performed by the partner based on the report and the
                        preliminary cyber risk rating. It is an evidence-based
                        audit and can also be done online. Dates and times are
                        agreed directly between you and the auditor.
                      </div>
                    </div> */}
                    <div
                      className={`application-procedure__left--box ${
                        steps[5] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(5)}
                      >
                        <strong>Possibility to clarify</strong> or correct open
                        issues
                      </div>
                      <div className="body">
                        In this phase eventual unclarities can be clarified and
                        missing evidence can be submitted in order to reach the
                        required qualification.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[6] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(6)}
                      >
                        <strong>Calculation</strong> of final Cyber Risk Rating
                      </div>
                      <div className="body">
                        Based on the audit results the final rating is
                        calculated. Only you receive a copy of the audit report
                        due to security reasons. KSV1870 and Cyber Trust
                        Services only receive the confirmation of the rating
                        resp. information if there were deviations from the
                        preliminary risk rating.
                      </div>
                    </div>
                    <div
                      className={`application-procedure__left--box ${
                        steps[7] ? " active" : ""
                      }`}
                    >
                      <div
                        className="name js-name"
                        onClick={() => toggleCard(7)}
                      >
                        <strong>Issuing</strong> of the label & recording in the
                        Cyber Trust Label Database
                      </div>
                      <div className="body">
                        If the qualification criteria for the label are met
                        (valid A-rating of 190 or better), you will receive a
                        label confirmation and the usage rights for one year.
                        Your company will also be recorded in the Cyber Trust
                        Label Database
                      </div>
                    </div>
                  </div>
                </div>
                <div className="application-procedure__buttons_container">
                  <a
                    onClick={() =>
                      navigate("/en/label-beantragen?label=SILVER")
                    }
                    className="page__btn page__btn--blue js-popup"
                    data-bg="#FFEBD1"
                    data-btnbg="#C89E67"
                    data-child="2"
                    data-rel="rel1"
                  >
                    Request
                  </a>
                  <Link
                    to="/en/list-question"
                    className="page__btn page__btn--blue-border"
                  >
                    Requirements
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default ApplicationProcedureSilver
